<template>
  <div
    class="meeting-page"
    ref="meeting"
  >
    <!-- :class="[orientation == 'landscape' ? 'landscape' : '']" -->
    <van-overlay v-if="loading" :show="true">
      <div class="wrapper" @click.stop>
        <div class="block" >
          <van-loading vertical>{{$t('meeting.goMeeting')}}</van-loading>
        </div>
      </div>
    </van-overlay>

    <div
      v-else
      class="content"
    >
      <!--
        重连loading 框
        所有按钮不可操作
        层级较高
      -->
      <van-overlay :show="isNetworkDisconnect" class-name="net-disconnect-overlay">
        <div class="wrapper" @click.stop>
          <div class="block" >
            <van-loading vertical>{{$t('meeting.instability')}}</van-loading>
          </div>
        </div>
      </van-overlay>

      <meeting-header
        :showToolbar="showToolbar"
        :orientation="orientation"
      ></meeting-header>

      <input type="hidden" id="speakerHiddenStatus" :value="1">
      <input type="hidden" id="speakerHiddenvolmue" :v-model="1">
      <input type="hidden" id="speakerDeviceId" v-model="currentSpeakerId">

      <gallery
        @switch-toolbar="switchToolbar"
        :orientation="orientation"
      ></gallery>

      <meeting-footer
        :showToolbar="showToolbar"
        :orientation="orientation"
      ></meeting-footer>


      <!-- 放大视频组件 -->
      <video-enlarge></video-enlarge>

      <!-- 横屏组件 -->
      <video-enlarge-two></video-enlarge-two>

    </div>

    <!-- 音频组件 -->
    <audio-list ref="audio-list"></audio-list>

    <exit v-model="showExit" @enableBack="enableBack" ref="exit"></exit>


    <!-- 提示音 -->
    <audio
      class="media-reset-flag"
      id="userEnterTipAudio"
      style="display: none;"
      src="@/assets/some_one_join_room.wav"
    >
    </audio>

    <!-- 会控组件 -->
    <meeting-control ref="meetingControlEl"></meeting-control>
  </div>

</template>

<script>
import Header from "./components/Header.vue";
import Gallery from "./components/Gallery.vue";
import Footer from "./components/Footer.vue";
import MeetingControl from "./components/MeetingControl.vue";
import Exit from './components/Exit.vue';
import AudioList from './components/AudioList.vue';
import popUpBottom from '@/components/PopUpBottom/index.vue';

import VideoEnlarge from './components/VideoEnlarge.vue'
import VideoEnlargeTwo from './components/VideoEnlargeTwo.vue'

import { QRTCVideoResolutionMode, QRTCVideoResolution } from '@ybmeet/yb_rtc_sdk/src/qrtc_web_sdk/RoomParams';
import { signalUrl } from "@/config/index";
import { getIOSVersion} from "@/utils/mobile"

// loganLog('QRTCVideoStreamType', QRTCVideoStreamType);

import {
  getUuid,
  getAvatar,
  getAcceptLanguage,
  setMeetInfo,
  getConferenceToken,
  getChannelToken,
  getFirst,
  setFirst,
} from "@/utils/auth";

import {
  fetchReconnectionInfo,
} from '../../api/meet';

import { loganLog } from "@/utils/log"

import { isSafari } from "@/utils/mobile"

export default {

  components: {
    "meeting-header": Header,
    "meeting-footer": Footer,
    "gallery": Gallery,
    "audio-list": AudioList,
    "meeting-control": MeetingControl,
    'pop-up-bottom': popUpBottom,
    exit: Exit,
    VideoEnlarge,
    VideoEnlargeTwo


  },

  data() {
    return {
      loading: true,
      isNetworkDisconnect: false,


      localUser: null,

      // UI 逻辑
      showToolbar: true,
      showExit: false,
      orientation: "portrait", // portrait: 竖屏、landscape: 横屏

      enlargeVideoShow: false,
      currentSpeakerId:''
    };
  },

  computed: {
    enableFace() {
      return this.$store.state.meet.enableFace
    },
    supportFace () {
      return this.$store.state.face.support
    },
  },

  created() {
    this.initRTCEvent();
    this.formatMeetInfo();
    this.setVideoEncoderParam();
 
    this.enterRoom();

    // 监听子组件 上报事件
    this.$eventBus.$on("show-module", (data) => this.showModule(data));

    this.checkEnvironment()
    // 记忆会场标示

    window.localStorage.setItem('appHide', this.$route.query.conferenceNo)

    //从上次操作记忆中加载 初始化美颜 默认轻度
    if (this.enableFace && this.supportFace) {
      console.error('会中初始化美颜数据..')
      this.$store.commit("face/applyFaceStateFromCache", true)
    }
  },

  mounted() {

    // ios 不处理

    if(
      window.__wxjs_environment === 'miniprogram'
      && !!navigator.userAgent.match(/iphone | ipad/i) //ios终端
    ) { // 小程序环境
      return
    }

    // window.history.pushState(null, null, window.location.href);
    // window.addEventListener('popstate', this.backCommon);

  },

  beforeDestroy() {

    // window.removeEventListener('popstate', this.backCommon);
  },

  methods: {
    backCommon() {
      if(this.showExit == false) {
        window.history.pushState(null, null, window.location.href);
        this.showExit = true;

        loganLog('Meeting.vue backCommon');
      }
    },

    enableBack() {
      this.showExit = false;

      loganLog('Meeting.vue enableBack');
      // 删除会场标示

      window.localStorage.removeItem('appHide')
      window.localStorage.removeItem('joinBeforeRoute');
      // ios 不处理

       //移除安卓手机小视频弹窗
      const videos = document.getElementsByTagName('video')
      for (let index = 0; index < videos.length; index++) {
         if (videos[index].getAttribute('crossorigin') === "anonymous") {
           document.body.removeChild(videos[index])
         }
      }
      //  三方URL
      // const thirdUrl = sessionStorage.getItem('thirdBackUrl')
      // if(thirdUrl) {
      //   localStorage.removeItem('exceptionExitExpires')
      //   jWeixin.miniProgram.navigateBack();
        // window.location.href = thirdUrl;
      //   return;
      // }
      
      this.backPreUrl()
      return

      if(
        window.__wxjs_environment === 'miniprogram'
        && !!navigator.userAgent.match(/iphone | ipad/i) //ios终端
      ) { // 小程序环境
        loganLog('Meeting.vue ios exit go -1')
        // this.$router.go(-1)
        history.go(-1)
        return
      }
    
      loganLog('Meeting.vue android exit go -2')
      // this.$router.go(-2)
      this.$router.go(-1)
    },
    backPreUrl(){
      loganLog('Meeting.vue exit go -1')
      this.$router.go(-1)
      setTimeout(() => {
        if(location.pathname.indexOf('meeting') != -1){
        loganLog('Meeting.vue  exit go -1 +1')
          this.backPreUrl()
        }
      }, 100);
    },
    /*****-------------------- 业务相关 ---------------------------*****/
    formatMeetInfo() {

      // 设置configs值
      const routeQuery = this.$route.query

      let source = {
        userId: this.$route.params.userID,
        roomId: routeQuery.roomID,
        conferenceNo : routeQuery.conferenceNo,
        userName : decodeURIComponent(routeQuery.userName),
        avatarUrl : getAvatar(),
        peerId : routeQuery.roomID + '_' + this.$route.params.userID,
        businessType : routeQuery.businessType
      }

      if(routeQuery.hospital){
        let hospital = decodeURIComponent(routeQuery.hospital)
        source.hospital = hospital;
      }

      this.$configs = Object.assign(this.$configs, source);

    },


    async getReconnectionInfo() {

      const {
        userId,
        conferenceNo,
        roomId
      } = this.$configs;

      try {
        const resData = await fetchReconnectionInfo({
          userId,
          deviceId: getUuid(),
          conferenceNo,
          roomId
        });

        return resData;

      } catch (error) {
        return error;
        throw new Error('getReconnectionInfo err');
      }
    },

    setVideoEncoderParam() {

      // 设置默认编码参数
      const encoderParams = {
        resMode: QRTCVideoResolutionMode.QRTCVideoResolutionModePortrait, // 竖屏
        videoResolution: QRTCVideoResolution.QRTCVideoResolution_640_360
      }

      this.sdk.rtc.setVideoEncoderParam(encoderParams)

    },

    enterRoom(params) {
      // 链接im
      this.sdk.im.enterRoom({
        channelToken: getChannelToken(),
        roomid:this.$configs.roomId
      });

      if(!params) {

        const {
          peerId,
          userName,
          roomId,
          avatarUrl
        } = this.$configs

        params = {
          userId: peerId,
          userName,
          roomId,
          avatarUrl,

          xConferenceToken: getConferenceToken(), // 获取会议token
          acceptLanguage: getAcceptLanguage(),

          // disableDataStats: true
        }

      }

      const routeQuery = this.$route.query
      // 进房
      if(routeQuery.businessType == 10007 && routeQuery.hospital){
        params.appData = {
          hospital : routeQuery.hospital
        }
      }
      this.sdk.rtc.startMeeting(params);

      if(
        window.__wxjs_environment === 'miniprogram'
      ) { // 小程序环境
        // 存储wsUrl
        
        let wsUrl = `wss://${signalUrl}?roomId=${params.roomId}&peerId=${params.userId}&forceH264=false&forceVP9=false`
        this.$store.commit("meet/updateGlobalMeetState", {
          wsUrl:wsUrl
        });
      }

      // const iosVer = getIOSVersion() //涛哥让去掉里面的弹窗
      // if(!getFirst() && iosVer && iosVer < 18){
      //   this.firstTips();
      // }
    },

    initRTCEvent() {

      // 用户相关的
      this.sdk.on('onEnteredMeeting', this.onEnteredMeeting);
      this.sdk.on('onRemoteUserEnterMeeting', this.onRemoteUserEnterMeeting);
      this.sdk.on('onRemoteUserLeaveMeeting', this.onRemoteUserLeaveMeeting);

      // 媒体流相关的
      this.sdk.on('onUserVideoAvailable', this.onUserVideoAvailable);
      this.sdk.on('onUserShareAvailable', this.onUserShareAvailable);

      // 异常监测
      this.sdk.on('networkQuality', this.onNetworkQuality);
      this.sdk.on('websocketMayDisconnected', this.onWebsocketMayDisconnected);
      this.sdk.on('disconnected', this.onDisconnected);
      this.sdk.on('websocketStillAlive', this.onWebsocketStillAlive);
      this.sdk.on('connectionTimeOut', () => {
        loganLog('meetting connectionTimeOut');

        this.shouldExit('timeout')
      });
      this.sdk.on('error', this.onError);
      this.sdk.on('enter-room-error',  () => this.shouldExit('enterRoomError'));

      // 本端音视频相关
      this.sdk.on('no-camera-grant', this.noCameraGrant)
      this.sdk.on('no-mic-grant', this.noMicGrant);



      // 监听日志
      this.sdk.on('sdkLogReport', msg => {
        loganLog(msg, 'sdk', 2)
      })

    },

    _addUser(user) {

        const targetUser = this.$store.state.member.userList
                        .filter(item => item.userId == user.userId)[0];


        if(targetUser) {
          this.$store.commit('member/updateUser', Object.assign(targetUser, user))
        } else {
          this.$store.commit('member/addUser', user);
        }

    },



    /****------------- SDK 监听事件 --------------------*****/
    onEnteredMeeting(selfTimeMs) {

      this.loading = false;

      if(!this.disconnected) this._initLocalUser(selfTimeMs);

      // 断网重连
      if(this.disconnected) {
        const _oldLocalUser = this.$store.getters["member/getUser"](this.$configs.peerId);
        this.$store.commit("member/disconnectedReset");

        this.$nextTick(() => this._initLocalUser(selfTimeMs, _oldLocalUser) );

        this.disconnected = false;
        this.isNetworkDisconnect = false;
      }

      this.reconnectionInfo();

      // 设置errorHandler 监听SDK 事件
      this.$eventBus.$emit('init-rtc-event');

      // 设置音频码率
      this.sdk.rtc.setAudioQualityLevel(3);

      // 设置 vuex userId
      this.$store.commit('user/SET_USERID', this.$configs.userId)

    },

    onRemoteUserEnterMeeting(userInfo) {
        const user = {
          userId: userInfo.userId,
          userName: userInfo.userName,
          avatarUrl: userInfo.avatarUrl,
          timeMs: userInfo.timeMs, // 入会时间

        }

        // 新增用户
        loganLog(`remote user add', ${JSON.stringify(user)}`,'[client]')
        this._addUser(user);

        this.$store.commit('member/addAudio', { // 创建 audio 标签
          userId: user.userId,
          status: 'init'
        })

        // 播放提示音
        try {
          const { playTips } = this.$store.state.meet;

          if (playTips && userInfo.timeMs > this.localUser.timeMs) {
            loganLog("播放提示音--");
            const tipAudioEl = document.getElementById("userEnterTipAudio");
            tipAudioEl && tipAudioEl.play();
          }
        } catch (error) {
          loganLog(error);
        }

    },

    onRemoteUserLeaveMeeting(userInfo) {
      // 在userList移除离开房间的用户
      this.$store.commit("member/removeUser", userInfo)

      // 在speakList中移除离开房间的用户
      this.$store.commit("member/removeSpeak", userInfo)

      // 判断lastSpeaker是否为当前离开房间的用户
      const lastSpeaker = this.$store.state.member.lastSpeaker
      if (lastSpeaker && lastSpeaker.userId === userInfo.userId) {
        this.$store.commit("member/updateLastSpeaker", null)
      }

      // 在audioList中移除用户
      this.$store.commit("member/removeAudio", userInfo);

      this.$eventBus.$emit('user-leave', userInfo.userId)

    },


    _initLocalUser(selfTimeMs, reconnectionStatus = {}) {

      // 检测能否开启麦克风
      let _check_allow_selfunmute = function(localUser) {
        const { allMuteState, muteJoinMeeting } = this.$store.state.meet;

        if(!localUser.isUseHuaTong) return localUser;


        if(!allMuteState && !muteJoinMeeting) return localUser;

        return Object.assign(localUser, {isUseHuaTong: false});


      }


      /**
       * 入会成功的一些处理
       * reconnectionStatus: 断网重连的状态记忆（本端媒体状态等）
       * */


        const { userRoleCode } = this.$store.state.meet;
        const { peerId, userName } = this.$configs;

        const strToBoolean = str => str == 'true' ? true : false;

        // 设置自己用户对象初始化信息
        this.localUser = {
          userId: peerId,
          userName: userName, // 用户名称
          avatarUrl: getAvatar(), // 头像信息

          isUseHuaTong: strToBoolean(localStorage.getItem("isUseHuaTong")),
          isUseShiPin: strToBoolean(localStorage.getItem("isUseShiPin")), // 是否开启视频

          isUseHuaTongError:false, //话筒放状态是否error
          isSpeaking: false,
          roleCode: userRoleCode,
          isRaiseHand: false,
          isRecord: false,
          recordPaused: false,
          isDbClick: false,
          timeMs: selfTimeMs,
        }

        // 如果不被允许开启麦克分，关闭麦克风
        this.localUser = _check_allow_selfunmute.bind(this,this.localUser)();

        // 如果有reconnectionStatus，合并自己断网之前的状态
        this.localUser = Object.assign(this.localUser, reconnectionStatus);

        this._addUser(this.localUser);

        const { isUseShiPin } = this.localUser;

        // 开启视频
        this.$nextTick(() => { // 子组件可能还未创建

          if(isUseShiPin) this.$eventBus.$emit('start-local-video');

          // 建立音频通道
          this.$eventBus.$emit('create-audio-transport')
        })
        // setTimeout(,50)


    },

    async reconnectionInfo() {

      try { // 同步会议状态

        const resData = await this.getReconnectionInfo();

           /**
        * attendList中的数据来源：
        * 用户本人和房间内其他用户的信息都有可能返回，只要满足以下任意一个条件：
        * 1. 不是普通用户
        * 2. 正在举手
        * 3. 正在录制
        */
        const {
          attendList,
          conference,
          roleCode,
          pushUrl
        } = resData;
        // console.error('1111111111111',pushUrl,resData)
        const conferenceToken = resData['X-Conference-Token']
        const channelToken = resData['X-Channel-Token']

        setMeetInfo(
          conferenceToken,
          channelToken
        )

        this.$router.replace({
          query: {
            ...this.$route.query,
            conferenceNo: conference.conferenceNo
          }
        })

        // 存储全局会议状态
        this.$store.commit("meet/updateGlobalMeetState", {
          allowEarlyEntry: conference.allowEarlyEntry,
          muteJoinMeeting: conference.muteJoinMeeting,
          playTips: conference.playTips,
          allowSelfUnmute: conference.allowSelfUnmute,
          ownerPasswordEnable: conference.ownerPasswordEnable,
          passwordEnable: conference.passwordEnable,
          agendaPermission: conference.agendaPermission,
          allMuteState: conference.allMuteState,
          recordPermission: conference.recordPermission,
          sharePermission: conference.sharePermission,
          lockedState: conference.lockedState,
          ownerName: conference.ownerName,
          ownerId: conference.ownerId,
          links: conference.links,
          userRoleCode: Number(roleCode),
          meetName: conference.title,
          password:conference.password,
          cloudRecorState:conference.cloudRecordState,
          rtmpPullUrl:conference.pullUrl || '',
          rtmpPushUrl:pushUrl || ''
        });

        if (Array.isArray(attendList) && attendList.length > 0) {
          attendList.forEach((user) => {
            // raiseHandStatus 举手状态 1:举手 0：手放下
            // recordStatus 录制状态 1：已录制  0：停止录制
            const { peerId, raiseHandStatus, recordStatus, roleCode } = user;

            const stateInfo = {
              isRaiseHand: !!raiseHandStatus,
              isRecord: !!recordStatus,
            };

            this._addUser({
              userId: peerId,
              roleCode: Number(roleCode),
              ...stateInfo,
            })

          });

        }

        loganLog(`reconnectionInfo success`,'[client]')

      } catch (error) {
        loganLog('meeting.vue startMeeting error', error);

      }

    },


    /***** -------- 异常检测 --------- ******/
    onNetworkQuality(data) {
      if(data.uplink > 3) {
        loganLog('local networkQuality', data);
      }
    },
    onDisconnected() {
      loganLog('meeting on: onWebsocketDisconnected');

      this.$eventBus.$emit('network-disconnect');
      this.isNetworkDisconnect = false;

      this.disconnected = true;

      this.$eventBus.$emit('collapse-fullscreen');
      this.$eventBus.$emit("resume-video-size");

    },
    onWebsocketMayDisconnected() {
      loganLog('meeting on: onWebsocketMayDisconnected');

      this.isNetworkDisconnect = true;

      this.$eventBus.$emit('collapse-fullscreen');
      this.$eventBus.$emit("resume-video-size");
    },

    onWebsocketStillAlive() {
      loganLog('meeting on: onWebsocketStillAlive');
      this.isNetworkDisconnect = false
    },

    onError(error) {
      loganLog('onErr', error);
    },

    noCameraGrant() {

      if(this.micGrantError) return;

      this.$dialog.confirm({
        message: '打开摄像头失败，无法获取权限，请关闭小程序重新打开后入会',
        confirmButtonText: '退出会议',
        confirmButtonColor: '#FF4D4F',
        cancelButtonText: '留在会场'
      })
      .then(this.$refs.exit.back)

      this.$store.commit('member/updateUser', {
        userId: this.$configs.peerId,
        isUseShiPin: false
      });
    },
    noMicGrant() {
      this.micGrantError = true; // 没有 mic 权限，不处理视频
      // 上线前打开注释
      this.$dialog.alert({
        message: '打开麦克风失败，无法获取权限，请关闭小程序重新打开后入会',
        confirmButtonText: '退出会议',
        confirmButtonColor: '#FF4D4F'
      }).then(this.$refs.exit.back)

      this.notify('打开麦克风失败，无法获取麦克风权限');
      this.$store.commit('member/updateUser', {
        userId: this.$configs.peerId,
        isUseHuaTong: false
      });

    },


    /*********** ----------------- 流媒体相关 ---------------- ***************/
    onUserVideoAvailable(videoInfo) {
      loganLog(`onUserVideoAvailable: ${videoInfo}`, '[client]' );

      let { userId, available } = videoInfo;

      let user = this.$store.getters["member/getUser"](userId);
      if (user.userId) {
        this.$store.commit("member/updateUser", {
          userId,
          isUseShiPin: available,
        })
      }
    },

    onUserShareAvailable(shareInfo) {
      const { userId } = shareInfo;
      loganLog(`userShareAvailable-----, user: ${shareInfo.userId}`);

      let user = this.$store.getters["member/getUser"](userId);

      if (user.userId) {

        const { userId, available, paused } = shareInfo;
        this.$store.commit("member/updateUser", {
          userId,
          isShare: available,
          sharePaused: !!paused,
        });

      }
    },

    // UI交互相关
    switchToolbar() {
      this.showToolbar = !this.showToolbar;
    },


    /**
     * 子组件上报指令，通过指令 显示 模块
     * moduleType: data 中控制模块的
    */
    showModule(moduleType) {
      if(moduleType) this[moduleType] = true;
    },

    firstTips(){
      this.$dialog.alert({
          title:'',
          message: this.$t('seeting.keepOnfrontTitle'),
          confirmButtonText: this.$t('login.know'),
        }).then(()=>{
          setFirst('keep_first')
        })
    },

        /**** ----------- 页面切后台检测 ----------------- ****/
    checkEnvironment() { //是否在小程序中运行
      function ready() {
        if(window.__wxjs_environment === 'miniprogram') { // 小程序环境
          document.addEventListener("visibilitychange",this.monitorPagehide);
          //修复safari下visibilitychange 不会触发的问题
          if (isSafari()) {
            console.error('pagehide监听了')
            window.addEventListener('pagehide', this.monitorPagehide)
          }
        }
      }

      ready = ready.bind(this);
      if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
        document.addEventListener('WeixinJSBridgeReady', ready, false)
      } else {
        ready()
      }
    },

    async monitorPagehide() {
      // console.error(3333311,document.hidden,document.visibilityState)
      loganLog('页面状态发生变化--，hidden、visibilitychange=',document.hidden,document.visibilityState);
      // if(!document.hidden){
      //   window.location.reload()
      // }
      const iosVer = getIOSVersion()
      if(iosVer && iosVer < 18 && (document.visibilityState == 'hidden' || document.hidden)){ //ios 18 走旧的退会逻辑 因为保活不稳定，ws偶现会断开 音视频设备权限偶现丢失
        this.sdk.rtc.leaveMeeting();
        loganLog('ios版本过低 退出页面-',iosVer)
        this.enableBack()
        return
      }
      if(document.visibilityState == 'hidden' || document.hidden) { //hidden、visible 隐藏可见
        // this.$refs.exit.reset()
        // this.sdk.rtc.leaveMeeting(true);
        const selfId = this.$configs.peerId
        const selfInfo = this.$store.getters["member/getUser"](selfId)
        if(selfInfo.isUseHuaTong){
          loganLog('页面隐藏关闭麦克风')
          this.sdk.rtc.muteLocalAudio(true).then(()=>{
            // 更改自身状态
            this.$store.commit('member/updateUser', {
              userId:selfId,
              isUseHuaTong: false
            })
            this.localUser.isUseHuaTong = false;
          })
          loganLog('页面隐藏关闭麦克风success')
        }
        if(selfInfo.isUseShiPin){
          loganLog('页面隐藏关闭摄像头')
          await this.sdk.rtc.stopLocalVideo()
          this.$store.commit("member/updateUser", {
            userId: selfId,
            isUseShiPin: false,
          });
          this.localUser.isUseShiPin = false;
          loganLog('页面隐藏关闭摄像头success')
        }
      }else{
        const resData = await this.getReconnectionInfo();
        if(resData.code == 60008){    
          this.$refs.exit.onExitMeeting({reason:3})
          return
        }
    
        loganLog(`111会议剩余时间-${resData.remainingDuration,resData.remainingDuration/60}`)
        if(resData.remainingDuration && resData.remainingDuration < 600){
          this.$eventBus.$emit('maturityReload', resData.remainingDuration); // 透传到 header 组件
        }
        // window.location.reload()
      }
      const expires = 30 * 60 * 1000; //30分钟
      localStorage.setItem('exceptionExitExpires', new Date().getTime() + expires);
    },

    /*** ------ 异常退出 ----- ****/
    shouldExit(exceptionMsg) {

      try {

        loganLog(`shouldExit: ${exceptionMsg}`, '[client]' );

        const { conferenceNo } = this.$route.query;

        localStorage.setItem('exceptionExitMsg', JSON.stringify({
          msg: exceptionMsg,
          conferenceNo
        }));

        this.$refs.exit.back();
      } catch (error) {
        loganLog('shouldExit error'+ JSON.stringify(error));
      }

    },


  },


  destroyed() {
    this.$eventBus.$off([
      "show-module",
      "unmute", "switch-camera",'start-local-video','stop-local-video',
      'create-audio-transport',
      'remove-self',
      'gallery-prev', 'gallery-next',

      'collapse-fullscreen',
      "enlarge-video",
      "resume-video-size",
      "enlarge-share",

    ]);

    // 移除监听
    document.removeEventListener("visibilitychange", this.monitorPagehide);
  }



};
</script>

<style lang="less" scoped>
.meeting-page {
  width: 100%;
  height: 100%;
  transition: 0.5s;
  font-size: 24px;
  color: #fff;

}

.content {
  width: 100%;
  //height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  background: #000;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

// loading
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 260px;
  height: 260px;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  opacity: 0.85;

  font-size: 28px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.van-loading__text {
  color: #fff;
  text-align: center;
}

.net-disconnect-overlay {
  background:rgba(0,0,0,.3);
  z-index: 9999;
}
// 修改 vant
.van-loading__spinner.van-loading__spinner--circular {
  width: 60px;
  height: 60px;
}
/* 横屏 */
.landscape.meeting-page {
  width: 100vh;
  height: 100vw;

  transform: rotate(90deg) translateX(-100vw);
  transform-origin: 0% 100%;

  transition: 0.5s;
}




</style>
