


<template>
  <div>

    <audio
      v-for="item in audioList"
      :key="item.userId"
      :id="`audio-${item.userId}`"

      autoplay
      style="display: none;"
    ></audio>
    
  </div>
</template>



<script>

// 新版 订阅逻辑(暂无 17路置换)

// 	1. 有人加入 store.addAudio
// 	2. 有人打开麦克风: 
// 		---> 有 audioEl, startRemoteAudio
// 		---> 无 audioEl, --> store.addAudio --> $next --> startRemoteAudio

// 	3. 发言方发言: 
// 		---> 有 audioEl, startRemoteAudio
// 		---> 无 audioEl, --> store.addAudio --> $next --> startRemoteAudio

// 	4. 关闭麦克风: audioEl.srcObject = null
// 	5. 有人离会: store.removeAudio


import { ROLE_CODE, MAX_AUDIO_TRANSPORT } from "@/constant/index";
import { loganLog } from '@/utils/log';
import * as retry from 'retry'



export default {


    data() {
        return {
            ROLE_CODE: ROLE_CODE,
        }
    },

    computed: {
      audioList() {
        return this.$store.state.member.audioList;
      }
    },

    created () {
        let _this = this
        // 音频流相关的
        this.sdk.on('onUserAudioAvailable', audioInfo => {
          _this.onUserAudioAvailable(audioInfo)
        })

        this.sdk.on('userSpeaking', speakingInfo => {
          _this.userSpeaking(speakingInfo)
        })

        // 音频不稳定
        this.sdk.on('audio-instable', (userId) => {
          loganLog(`连续几秒（5/10/15）未收到订阅的指定流的音频包, userId: ${userId}`);

          this.$store.commit("member/updateUser",  {
            userId,
            isUseHuaTongError: true
          })
        })

        // 音频恢复稳定
        this.sdk.on('audio-stable', (userId) => {
          loganLog(`重新收到订阅的指定流的音频包, userId: ${userId}`);
          
          this.$store.commit("member/updateUser",  {
            userId,
            isUseHuaTongError: false
          })

        });
    },



    methods: {

      /**** ----------------- SDK 语音回调 -------------------- ****/
      onUserAudioAvailable(audioInfo) {

        loganLog('远端音频状态监听回调 info: ' + JSON.stringify(audioInfo))

        let { userId, available } = audioInfo;
        let user = this.$store.getters["member/getUser"](userId)

        if(!user.userId) return;

        this.$store.commit("member/updateUser", {
          userId,
          isUseHuaTong: available,
          isUseHuaTongError: false
        });

        /******* ---------- available: true ------------ ********/
        if(available) {
          this._startRemoteAudioHandle(userId);

          return;
        }

        /******* ---------- available: false ------------ ********/
        const audioEl = document.querySelector(`#audio-${userId}`);
        if(audioEl) {
          audioEl.srcObject = null;
        }
        
      },

      userSpeaking(speakingInfo) {

        loganLog(`userSpeaking info: ${JSON.stringify(speakingInfo)}`);

        const { userId } = speakingInfo;

        const user = this.$store.getters["member/getUser"](userId)

        if(!user.userId) return;

        this._updateSpeaker(speakingInfo);

        if(user.userId == this.$configs.peerId) return; // 自己的不加入到 audioList

        if(action == 'unmute') { // 订阅远端视频
          this._startRemoteAudioHandle(userId, 'unmute')
        }

      },



      /**** ------------ 订阅逻辑 ---------------- ****/
      _startRemoteAudioHandle(userId, status) {
        console.log(`_startRemoteAudioHandle userId: ${userId}, status: ${status}`);
        // 开始订阅
        const audioEl = document.querySelector(`#audio-${userId}`);
        if(!audioEl) {
          this.$store.commit('member/addAudio', {
            userId,
            status
          })

          this.$nextTick(() => {
            const _audioEl = document.querySelector(`#audio-${userId}`);
            this.startRemoteAudio(userId, _audioEl)
          })
        } else {
          this.startRemoteAudio(userId, audioEl)
        }


      },

      // 带有重试的 订阅远端音频
      startRemoteAudio (userId, audioEl) {

        const operation = retry.operation({
          retries: 3,
          minTimeout: 0
        });


        operation.attempt(async (currentAttempt) => {
          loganLog(`startRemoteAudio retry次数-----${currentAttempt}, userId: ${userId}, el: ${this.$el}`);

          /**** --------- 真正的订阅逻辑 --------- ****/
          try {
            await this.sdk.rtc.startRemoteAudio(userId, audioEl);

            audioEl.addEventListener('pause', () => {
              console.log('audio pause userId', userId);
              // this.$dialog.confirm({
              //   title:'允许播放', 
              //   confirmButtonText: '确定',
              //   // cancelButtonText: '重定位'
              //   cancelButtonText: '取消'
              // }).then(() => {
              //   console.error('点击确定',audioEl)
              //   audioEl.play();
              // }).catch((error) => {
              //   console.error('点击取消',audioEl)
              //   audioEl.play();
              // })

              setTimeout(async () => {
                console.log('pause after start play');
                try {
                  audioEl.play();
                } catch (error) {
                  audioEl.play();
                }
              },100)
            })
          } catch (error) {

            loganLog(`[debug info] startRemoteAudio() | Error : ${error}`);

            if (!operation.retry(new Error())) { // 已达到最大返回数量
              loganLog(`订阅音频超过最大重试次数-----${currentAttempt}`);
              this.$store.commit("member/updateUser",  {
                userId,
                isUseHuaTongError: true
              })

            }
          }


        })





      },


      // 更新发言者
      _updateSpeaker(speakingInfo) {
        const { userId, action } = speakingInfo;

        const user = this.$store.getters["member/getUser"](userId);

        if(!user) return;

        if (action === "unmute") {
          // 添加到用户发言列表

          this.$store.commit("member/addSpeak", user)
          this.$store.commit("member/updateLastSpeaker", user)
        }

        if (action === "stop") {
          // 移除

          this.$store.commit("member/removeSpeak", {
            userId: userId
          })
        }
      },


      // 获取 需要删除的
      _getRemoveAudioItem () {
        const audioList = this.audioList;

        const isAudioPause = audioList.find(i => i.pause)
        if (isAudioPause) { // pause 的用户
          return isAudioPause
        }

        const isAudioStop = audioList.find(i => i.action === 'stop')
        if (isAudioStop) { // 存在action: stop
          return isAudioStop
        }

        const isAudioMute = audioList.find(i => i.action === 'mute')
        if (isAudioMute) { // 存在action: mute
          return isAudioMute
        }

        const isAudioStart = audioList.find(i => i.action === 'start')
        if (isAudioStart) { // 存在action: start
          return isAudioStart
        }

        const isAudioUnmute = audioList.find(i => i.action === 'unmute')
        if (isAudioUnmute) { // 存在action: unmute
          return isAudioUnmute
        }

        return null
      }

    },



}
</script>


<style lang="less" scoped>




  div {
    box-sizing: border-box;
  }

  .video-wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    background: #222;

    video {
      width: 100%;
      height: 100%;
    }

    .user-info {
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      left: 0;
      bottom: 0;
      z-index: 9;




      .displayName {
        // width: 100%;
        display: flex;
        align-items: center;
        height: 40px;

        background-color:rgba(0, 0, 0, .5);
        border-radius: 0px (8px) 0px 0px;
        padding: 0 8px;

        max-width:calc(100% - 100px);



        svg {
          flex-shrink: 0;
        }

        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }

      }
    }

    .no-video-placeholder {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 8;

      display: flex;
      justify-content: center;
      align-items: center;

      .name {
        width: 128px;
        height: 128px;
        background: #1AB370;
        border-radius: 128px;

        text-align: center;
        line-height: 128px;
      }

      .avatar {
        width: 72px;
        height: 72px;
        border-radius: 100%;
      }
    }

    .speaking-highlight {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;

      border: (3px) solid #1AB370;
    }

    .van-loading {
      position: absolute;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

</style>
